.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: .8rem;
    color: #1a8fb3;
    content: "/";
}

.breadcrumb {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: .75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #CFEDF1;
    border-radius: .25rem;
    color: #1C7B97!important;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
}

.breadcrumb-item:last-child a{
    color: #43575A;
}

.breadcrumb-item:last-child a:hover{
    color: #43575A;
}

.breadcrumb-item {
    display: -ms-flexbox;
    display: flex;
}

.breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem;
    padding-top: 2px;
  }

.breadcrumb a {
    color: #1C7B97;
    text-decoration: none;
}

.breadcrumb a:hover {
    color: #1C7B97;
    text-decoration: none;
}
  
