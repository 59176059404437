@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
  background-color: #E1E5E5;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: rgba(33,61,88,0.1);
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #B6BEBF; 
  border-radius: 10px;
}

.labels {
  color: #43575A;
  font-family: "Nunito Sans";
  font-size: 16px;
  line-height:27px;
  width: 130px; 
  visibility: hidden;
  z-index: 1;
}

.active{
  z-index: 1;
  color: #366412;
  font-family: "Nunito Sans";
  font-weight: bold;
  font-size: 16px;
  visibility: visible;
  line-height:27px;
}



.check-label {
  height: 42px;
  margin-bottom: 0px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {display:none;}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #F6F7F7;
  Border:Solid 1px #B6BEBF;
  box-shadow: 0.1px 0.1px 3px rgba(33,61,88,0.4) inset;
  transition: .4s;
}

.slider:before {
  position: absolute;
  z-index: 0;
  content: "";
  height: 36px;
  width: 170px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  Border:Solid 1px #B6BEBF;
  box-shadow: 0.1px 0.1px 3px rgba(33,61,88,0.4);
  transition: .4s;
}

input:checked + .slider {
  color: #366412;
  font-family: "Nunito Sans";
  Size: 16px;
  Border:Solid 1px #B6BEBF;
  box-shadow: 0.1px 0.1px 3px rgba(33,61,88,0.4) inset;
  background-color: #F6F7F7;
  line-height:42px;
  content: attr(data-on);
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  transform: translateX(174px);
}

/* Rounded sliders */
.slider.round {
  color: #43575A;
  font-family: "Nunito Sans";
  font-size: 16px;
  line-height:42px;
  border-radius: 22px;
  box-shadow: 0.1px 0.1px 3px rgba(33,61,88,0.4) inset;
  width: 350px;
  height: 42px;
}

.slider.round:before {
  border-radius: 22px;
}
.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: .8rem;
    color: #1a8fb3;
    content: "/";
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: .75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #CFEDF1;
    border-radius: .25rem;
    color: #1C7B97!important;
    font-family: "Nunito Sans";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
}

.breadcrumb-item:last-child a{
    color: #43575A;
}

.breadcrumb-item:last-child a:hover{
    color: #43575A;
}

.breadcrumb-item {
    display: flex;
}

.breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem;
    padding-top: 2px;
  }

.breadcrumb a {
    color: #1C7B97;
    text-decoration: none;
}

.breadcrumb a:hover {
    color: #1C7B97;
    text-decoration: none;
}
  

